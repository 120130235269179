.text-area {
    margin: 20px;
    padding-left: 20px;
    margin-bottom: 0;
}

.homepage{
    position: relative;
    height: 86vh;
    
}

.header {
    padding: 2px;
}

div.homepage u {
    font-size: 130%;
}

.media-links{
    padding-top: 10px;
    text-align: center;
}

img{
    padding-left: 2px;
    padding-right: 2px;
}