.postcard-holder {
    padding-bottom: 0px;
}

h2 {
    margin-bottom: -10px;
    font-size: larger;
}

.postcard {
    border: 1px solid black;
    border-radius: 10px;
    padding: 0% 2%;
    background-color: rgba(227, 54, 255, 0.5);
}

.postcard:hover {
    background-color: rgba(227, 54, 255, 0.4);
    cursor: pointer;
}
