.body {
    margin-top: 30px;
    border-style: solid;
    background-color: #F5f589;
}

@media (min-width: 801px){
    .body{
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 800px){
    .body{
        margin: 10px;
    }
}

.background::before {
    content: "";
    position: fixed;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 2px 2px, black 1px, transparent 0);
    background-size: 25px 25px;
    z-index: -1;
}

.content {
    overflow: auto;
}

hr {
    border: 0.5px solid black;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.route-enter {
    animation: fadeIn 0.2s ease-in-out forwards;
}

a{
    text-decoration: none;
    color: black;
}

a:hover {
    filter: brightness(.9);
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
}

@media (max-height: 46rem){
    .footer {
        position: relative;
    }
}

hr {
    margin-bottom: 0%;
}

