.markdown-body {
    position: relative;
    top: 0px;
    bottom: 50px;
    padding: 100px;
    padding-top: 30px;
    background-color: rgba(33, 36, 48, 1);
    color: white;
    overflow: auto;
}

@media (max-width: 1000px){
    .markdown-body {
        padding: 15px;
    }
}

.markdown a:any-link {
    color: #008bc7;
    text-decoration: underline; 
}

.markdown em {
    color: yellow;
}

.markdown strong{
    color: #00B2FF; 
}

.title {
    color: #ef5350;
}

.markdown h1 {
    color: #ef5350;
}

.markdown h2 {
    color: #ffa726;
}

.markdown h3 {
    color: #9ff369;
}

.markdown h4 {
    color: #59d5c4;
}

.markdown h5 {
    color: #2a8aff;
}

.markdown h6 {
    color: #ce93d8;
}

.markdown tr {
    border-top: 1px solid #c6cbd1;
    background: #f1f1f1;
}

.markdown th,td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

.markdown table tr:nth-child(2n) {
    background: #dcdcdc;
}